<template>
  <div>
    <UCard
        :ui="
        props.isOverlay
        ? {}
        : {
          header: {padding: 'px-2 py-2 sm:p-2 lg:p-4'},
          body: {padding: 'px-2 py-2 sm:px-2 lg:p-4'},
          footer: {padding: 'px-2 py-2 sm:px-2 lg:p-4'}
        }"
        class="flex flex-col flex-1">
      <template #header>
        <div class="flex items-center justify-between">
          <h3 class="flex gap-x-2 -text-base font-semibold leading-6 text-gray-900 dark:text-white items-center">
            <UIcon name="i-ri-menu-line"/>
            Menu
          </h3>
          <UButton v-if="props.isOverlay" class="-my-1" color="gray" icon="i-ri-close-line"
                   variant="ghost" @click="emit('closeOverlay')"/>
        </div>
      </template>
      <UVerticalNavigation
          :links="menu"
          :ui="{
      label: 'truncate relative py-1'
    }"
      />
      <template #footer>
        <div class="flex justify-between items-center">
          <UCheckbox v-model="isDark" help="Przełącz się pomiędzy jasnym i ciemnym motywem.">
            <template #label>
            <span class="flex justify-between items-center">
              <span> Nocna zmiana <span>(beta)</span></span>
              <UIcon :name="isDark ? 'i-ri-moon-fill' : 'i-ri-sun-line'"/>
            </span>
            </template>
          </UCheckbox>
        </div>
      </template>
    </UCard>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  isOverlay: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['closeOverlay']);

const {data} = useAuth();
const user = data.value?.user;

const colorMode = useColorMode();
const isDark = ref(colorMode.value === 'dark');

watch(isDark, (value) => {
  colorMode.preference = value ? 'dark' : 'light';
});

const menu = [
  {
    label: 'Strona główna',
    'aria-label': 'Strona główna',
    icon: 'i-ri-home-line',
    to: '/',
    click: () => emit('closeOverlay'),
  },
  {
    label: 'Profil użytkownika',
    'aria-label': 'Profil użytkownika',
    avatar: user?.image ? {src: user.image} : undefined,
    icon: !user?.image ? 'i-ri-user-line' : undefined,
    to: '/profiles/me/',
    click: () => emit('closeOverlay'),
  },
  {
    label: 'Powiadomienia',
    'aria-label': 'Powiadomienia',
    icon: 'i-ri-notification-line',
    to: '/notifications/',
    badge: 0,
    click: () => emit('closeOverlay'),
  },
  {
    label: 'Ustawienia',
    'aria-label': 'Ustawienia',
    icon: 'i-ri-settings-line',
    to: '/settings/',
    click: () => emit('closeOverlay'),
  }
];
</script>
